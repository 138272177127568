@use "./src/scss/global.scss" as *;

.marquee {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  min-width: 100%;
  align-items: center;

  &-content {
    display: flex;
    flex-shrink: 0;
    align-items: center;
  }

  &-item {
    padding-left: 140px !important;
    line-height: 80px !important;
    margin-top: 3px;
    text-transform: uppercase;
  }

  &-item-black {
    color: #13062f !important;
  }

  .direction-left {
    animation: move-right-to-left linear 20s infinite;
  }

  .direction-right {
    animation: move-left-to-right linear 20s infinite;
  }

  @keyframes move-right-to-left {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes move-left-to-right {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
}
