.loader {
  position: relative;
  top: -2px;
  left: -1px;

  img {
    width: 19.8px;
    height: 19.8px;
    animation: spin linear 1s infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
