@use "./src/scss/global.scss" as *;

#header {
  width: 100%;
  background-image: url("../../../../public/images/png/video-mock.png");
  background-size: 100% 100%;
  position: relative;
  height: calc(100vh - 80px);

  @include mq($smallTablet) {
    height: 100%;
  }

  .video {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $gradient1;
    opacity: 0.5;
    mix-blend-mode: overlay;
  }

  .header-contnet {
    z-index: 1;
    position: relative;
    padding: 142px 0 140px;
    height: 100%;
    @include flex-center;
  }

  .flex-column-center {
    @include flex-column(center, center);
  }

  .logo {
    width: 561px;
    height: 184px;
  }

  .logo-text {
    font-size: 32px !important;
    line-height: 32px !important;
    margin: 16px 0 66px;
  }

  .header-button {
    margin-bottom: 25px;
  }

  @include mq($tablet) {
    .logo {
      width: 324px;
      height: 110px;
    }

    .logo-text {
      font-size: 16px !important;
      line-height: 16px !important;
      margin: 10px 0 93px;
    }

    .header-button {
      font-size: 16px !important;
      width: 198px !important;
    }
  }
}
