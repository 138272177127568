.socialButton {
  text-decoration: none;
  width: max-content;
  height: max-content;
  cursor: pointer;

  img:hover {
    filter: brightness(0.8);
  }
}
