@use "./src/scss/global.scss" as *;

.roadmapCard {
  @include flex-column(center, center);
  margin-bottom: 76px;
  will-change: transform;

  h3 {
    margin-bottom: 0 !important;
  }

  hr {
    width: 100%;
    max-width: 642px;
    height: 2.4px !important;
    opacity: 1 !important;
    margin: 0 0 41.5px;
    color: $lavender;

    @include mq($tablet) {
      width: 95%;
    }
  }
}
