//====================================
$primary: #43fd8f;
$secondary: #ac58ff;
$dark-green: #5dce8b;
$lavender: #b87bf4;
$purple: #5f339f;
$navy: #10042b;
// Font colors
$white: #ffffff;
$white-medium: #fafafa;
$blue-dark: #13062f;
$violet: #290d67;
//====================================

//====================================
$gradient1: linear-gradient(180deg, #11052d 46.31%, #4618ac 132.72%);
$gradient2: radial-gradient(50% 50% at 50% 50%, #b76fff 2.08%, #b76fff 100%);
//====================================

//====================================
// Breakpoints
$mobile: 375px;
$smallTablet: 576px;
$tablet: 768px;
$bitTablet: 991px;
$desktop: 1200px;
$bigDesktop: 1400px;
//===================================
