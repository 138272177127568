@use "./src/scss/global.scss" as *;

#footer {
  background-color: $navy;
  width: 100%;
  height: max-content;
  overflow: hidden;
  padding: 0 40px;

  @include mq($tablet) {
    padding: 0;
  }

  .footer-logo {
    @include flex-center;
    padding: 108px 0 72px;

    img {
      width: 231.79px;
      height: 40.27px;
    }

    @include mq($tablet) {
      padding: 187px 0 104px;
    }
  }

  .footer-content {
    @include flex-center;
    margin-bottom: 117px;

    .border {
      border: 1px solid red;
    }

    .links {
      display: flex;
      justify-content: flex-end;
    }

    .policy {
      font-size: 14px !important;
      line-height: 24px !important;
      margin-bottom: 0px;
    }

    @include mq($tablet) {
      @include flex-column(flex-start, center);
      flex-wrap: nowrap;
      margin-bottom: 74px;

      .social-icons {
        margin: 0 auto 129px;
      }

      .links {
        flex-direction: column;
        margin-bottom: 25px;
        margin-left: 25px;
        row-gap: 10px;
      }

      .policy {
        margin-left: 25px;
      }
    }
  }
}
