@use "./src/scss/global.scss" as *;

#roadmap {
  background: $gradient1;
  overflow: hidden !important;
  display: flex;

  .roadmap-margin {
    margin-top: -20px;
    background-image: url("../../../../public/images/svg/bg-plus.svg");
    background-repeat: repeat;
    opacity: 0.15;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    flex: 1;
  }

  .roadmap-header {
    position: relative;
    height: 460px;

    @include mq($tablet) {
      height: 240px;
    }

    @include mq($smallTablet) {
      height: 190px;
    }

    img {
      position: absolute;
      top: 107px;
      left: 50%;
      transform: translateX(-50%);
      width: 2599.8px;
      height: 296.3px;
      z-index: 0;

      @include mq($tablet) {
        width: 100vw;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .header-blur {
      position: absolute;
      top: 107px;
      width: 1167.8px;
      height: 340px;
      background: $gradient2;
      mix-blend-mode: normal;
      filter: blur(214px);
      z-index: 1;

      @include mq($tablet) {
        width: 723.98px;
        height: 212px;
        filter: blur(112.67px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      @include mq($smallTablet) {
        width: 361.99px;
        height: 146.48px;
        filter: blur(66.3346px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .heading-box {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    h1 {
      position: absolute;
      top: 107px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;

      @include mq($tablet) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
