@use './src/scss/global.scss' as *;

.social-bar {
  justify-content: center;

  .flex-space-between {
    @include flex-row(space-between);
    width: 100%;
  }

  &-large {
    img {
      width: 49px;
      height: 49px;
    }

    @include mq($tablet) {
      img {
        width: 33px;
        height: 33px;
      }
    }
  }

  &-small {
    img {
      width: 26px;
      height: 26px;
    }
  }
}
