@use "./src/scss/global.scss" as *;

.cardItem {
  @include flex-row(center);
  will-change: transform;

  &-text {
    margin-left: 10px;
    margin-bottom: 0;
  }
}
