@use './src/scss/global.scss' as *;

#social-media {
  width: 100%;
  height: max-content;
  background-color: $purple;
  overflow: hidden;

  .content {
    margin: 84px 0 54px;
    @include flex-column(center, center);
  }
}
