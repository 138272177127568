@use "./variables.scss" as *;
@use "./mixins.scss" as *;

h1,
h2,
h3,
h4 {
  font-family: "Syncopate", sans-serif;
}

h1 {
  @include text-scaffold(144px, 700, $white-medium, 331px);
  @include mq($desktop) {
    @include text-scaffold(110px, 700, $white-medium, 331px);
  }
  @include mq($tablet) {
    @include text-scaffold(48px, 700, $white-medium, 113px);
  }
}

h2 {
  @include text-scaffold(80px, 700, $white-medium, 209px);
  @include mq($tablet) {
    @include text-scaffold(32px, 700, $white-medium, 84px);
  }
}

h3 {
  @include text-scaffold(60px, 700, $white-medium, 132px);
  @include mq($tablet) {
    @include text-scaffold(60px, 700, $white-medium, 132px);
  }
}

h4 {
  @include text-scaffold(51.6px, 700, $white-medium, 116px);
  @include mq($tablet) {
    @include text-scaffold(32px, 700, $white-medium, 74px);
  }
}

p {
  @include text-scaffold(20px, 400, $white, 43px);
  @include mq($tablet) {
    @include text-scaffold(16px, 400, $white, 43px);
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}
