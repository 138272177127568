@use "./src/scss/global.scss" as *;

.button {
  @include flex-center;
  height: 53.1px;
  width: 220px;
  border: none;
  outline: none;
  border-radius: 41.7px;
  padding: 0 25px;
  cursor: pointer;

  &-contained {
    @include text-scaffold(19.6px, 700, $blue-dark, 20px);
    background-color: $primary;

    &:hover {
      background-color: $dark-green;
    }
  }

  &-outlined {
    @include text-scaffold(19.6px, 700, $white, 20px);
    background-color: transparent;
    border: 2.46px solid;
    border-color: $primary;
    border-radius: 41.7px;

    &:hover {
      border-color: $dark-green;
    }
  }

  &-icon {
    margin-left: 10px;
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    .button-icon {
      transform: scale(1.2);
    }
  }

  &-link {
    text-decoration: none;
    width: max-content;
    height: max-content;
  }
}
