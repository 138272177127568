@use "./mixins.scss" as *;
@use "./variables.scss" as *;

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Geomanist", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

html {
  scroll-behavior: smooth;
}

html,
body,
#root,
section {
  width: 100%;
  height: max-content;
}

section {
  position: relative;
}

.container-xl {
  max-width: 1440px !important;
  background-color: transparent;
  height: 100%;
  position: relative;
  @include mq($tablet) {
    overflow-x: hidden;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.container-lg {
  max-width: 1040px !important;
  background-color: transparent;
  height: 100%;
  position: relative;
  @include mq($tablet) {
    overflow: hidden;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.desktop-block {
  display: block !important;
  @include mq($tablet) {
    display: none !important;
  }
}

.mobile-block {
  display: none !important;
  @include mq($tablet) {
    display: block !important;
  }
}
