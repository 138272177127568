@use "./src/scss/global.scss" as *;

.link {
  text-decoration: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $white;
  text-transform: uppercase;

  &:hover {
    filter: brightness(0.8);
    color: $white;
  }
}
